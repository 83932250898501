import React, { useState } from "react"
import Header from "../components/header"
import { Link } from "gatsby"

export default () => {
  const [body, setBody] = useState({ name: "", email: "", content: "" })

  const url =
    "https://i9h0muxuei.execute-api.us-east-1.amazonaws.com/dev/email/send"

  function onSubmit(e) {
    e.preventDefault()
    let req = new XMLHttpRequest()
    req.open("POST", url, true)
    req.setRequestHeader("Content-Type", "application/json")
    req.addEventListener("load", function() {})
    req.send(JSON.stringify(body))
  }
  function handleChange(e) {
    setBody({ ...body, [e.target.name]: e.target.value })
  }

  return (
    <>
      <Header title={"Contact"} />
      <Link to="/">Home</Link>
      <form id="contactForm">
        <label>Nombre</label>
        <input
          type="text"
          placeholder="Name"
          name="name"
          required
          onChange={handleChange}
        />
        <br />
        <label>Email</label>
        <input
          type="email"
          placeholder="Email Address"
          name="email"
          required
          onChange={handleChange}
        />
        <br />
        <label>Mensaje</label>
        <textarea
          rows="5"
          placeholder="Message"
          name="content"
          required
          onChange={handleChange}
        ></textarea>
        <br />
        <button onClick={onSubmit} id="submit" type="submit">
          Enviar
        </button>
      </form>
    </>
  )
}
